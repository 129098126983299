$(document).ready(function () {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *

    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *

    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIcons
    // *
    // *

    gsap.registerPlugin(ScrollTrigger);
    let icons = gsap.utils.toArray('.js-icon');
    // console.log(icons);
    icons.forEach(function (icon) {
        ScrollTrigger.create({
            trigger: icon,
            markers: false,
            start: "center 75%",
            end: "center 25%",
            onEnter: function () {
                // console.log("onEnter");
                icon.classList.add("active")
            },
            onLeaveBack: function () {
                // console.log("onLeaveBack");
                icon.classList.remove("active")
            },
            onEnterBack: function () {
                // console.log("onEnterBack");
                icon.classList.add("active")
            },
            onLeave: function () {
                // console.log("onLeave");
                icon.classList.remove("active")
            }
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mobileHover
    // *
    // *

    $('.js-mobile-hover').on('touchstart touchend ', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * simpleParallax
    // *
    // *

    let parallaxImages = document.getElementsByClassName('js-parallax');
    new simpleParallax(parallaxImages, {
        delay: 1,
        scale: 1.25
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *

    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();

        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *

    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    
    $(".js-nav-button").click(function () {
        $(".js-header").toggleClass("active");
        $(".js-side-cta").removeClass("active");
        $("body").toggleClass("freeze")
    });

    $(".js-header").hover(function () {
        $(".js-side-cta").removeClass("active");
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * header mobile nav
    // *
    // *
    
    $(".js-header-mobile-parent-icon").click(function () {
        $(this).parents("li").find(".js-header-child-list ul:first").addClass("active");
        $(this).parents(".js-header-parent").addClass("hide");
        $(this).parents("li").siblings("li").addClass("hide");
        $(".js-header-mobile-back").addClass("active");
    });

    $(".js-header-mobile-back").click(function () {
        $(".js-mobile-nav").find("*").removeClass("hide").removeClass("active");
        $(this).removeClass("active");

    });



    // Beim laden hide und active setzen damit nur die aktuelle Navigation ebene angezeigt wird
    if ($(".js-header-child-list").find("li.active").length > 0) {
        var lastActiveElement = $(".js-header-child-list").find("li.active").last();
        var activeList = lastActiveElement.parents(".js-header-child-list").first();
        activeList.find("ul").addClass("active");
        activeList.parents("ul").addClass("active");
        activeList.siblings(".js-header-parent").addClass("hide");
        activeList.siblings(".js-header-parent").parents("li").siblings("li").addClass("hide");
        activeList.parents(".js-header-child-list").siblings(".js-header-parent").addClass("hide");
        activeList.find(".js-header-child-list ul").removeClass("active");
        $(".js-header-mobile-back").addClass("active");
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * prevent
    // *
    // *

    $(".js-header [data-id=\"91\"]").click(function (e) {
        e.preventDefault();
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cta
    // *
    // *
    
    $(".js-side-cta").click(function () {
        $(this).toggleClass("active")
    });

    // $('.js-cta').on('touchstart touchend', function (e) {
    //     $(this).toggleClass('active');
    // });

    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video play/pause
    // * 
    // *

    $(".js-video").click(function () {
        $(this).toggleClass("active");
        var video = $(this).find(".js-video-src").get(0);
        video.paused ? video.play() : video.pause();
    });

    
    
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *  video mobile/desktop
    // *
    // *
    
    let windowWidth = window.innerWidth;
    let data = $(".js-video-src").data("desktop");
    if (windowWidth < 768) {
        data = $(".js-video-src").data("mobile");
    }
    $(".js-video-src").attr("src", data);
    $(".js-video-src source").attr("src", data);



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video pause when out of view
    // *
    // *
    
    // let io = new IntersectionObserver((entries) => {
    //     entries.forEach(entry => {
    //         if (!entry.isIntersecting) {
    //             $(entry.target).get(0).pause();
    //         }
    //         else {
    //             $(entry.target).get(0).play();
    //         }
    //     });
    // });

    // let heroVideo = $('.js-video-insersection');
    // if (heroVideo.length) {
    //     io.observe(heroVideo[0]);
    // }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fancybox - tiktok
    // *
    // *

    $(".js-tiktok").fancybox({
        iframe: {
            css: {
                "max-width": '400px',
                "max-height": '711px'
            }
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scrollSlider
    // *
    // *
    const sliderGallery = document.querySelectorAll(".js-scroll-slider");

    sliderGallery.forEach((element) => {

        const containerMaxWidth = 1470;
        const containerMargin = 80

        const sliderWidth = element.scrollWidth;
        let scrollWidth = 0;
        if (window.innerWidth < 1470) {
            scrollWidth = sliderWidth - window.innerWidth + containerMargin
        }
        else {
            scrollWidth = sliderWidth - containerMaxWidth - containerMargin;
        }
        
        gsap.to(element, {
            x: -scrollWidth,
            ease: "none",
            scrollTrigger: {
                trigger: element,
                scrub: true,
                pin: true,
                markers: false,
                start: "top+=5% center-=30%",
                end: "bottom+=1500 center+=30%"
            }
        })
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-header").click(function () {
        $(this).parent(".js-accordion-item").toggleClass("active");
        $(this).siblings(".js-accordion-body").slideToggle();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * desktop menu
    // *
    // *

    var mainMenuDataIds = document.querySelectorAll('a[data-id="91"], a[data-id="110"]');
    var megaMenuLayer = document.querySelector('.header__mm');
    var menuElements = document.querySelectorAll('.header__nav > ul > li > a');

    if (document.body.classList.contains('headerplus')) {
        mainMenuDataIds.forEach(function (mainMenuDataId) {
            mainMenuDataId.addEventListener('mouseover', function () {
                megaMenuLayer.classList.add('header__mm--active');
            });
        });

        menuElements.forEach(function (menuElement) {
            menuElement.addEventListener('mouseover', function () {
                if (menuElement.dataset.id !== '91' && menuElement.dataset.id !== '110') {
                    megaMenuLayer.classList.remove('header__mm--active');
                }
            });
        });

        megaMenuLayer.addEventListener('mouseleave', function () {
            megaMenuLayer.classList.remove('header__mm--active');
        });
    }
    


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * desktop menu
    // *
    // *

    var hasSubmenu = document.querySelectorAll('.js-has-submenu');

    function closeAllSubmenusExcept(exceptSubmenu) {
        for (var i = 0; i < hasSubmenu.length; i++) {
            var submenu = hasSubmenu[i].querySelector('.js-submenu');
            if (submenu !== exceptSubmenu) {
                hasSubmenu[i].classList.remove('active');
            }
        }
    }

    if (hasSubmenu !== null) {
        for (var i = 0; i < hasSubmenu.length; i++) {
            var arrow = hasSubmenu[i].querySelector('.js-arrow');
            arrow.addEventListener('click', function (event) {
                event.stopPropagation();
                var menuItem = this.parentElement.parentElement;
                var submenu = menuItem.querySelector('.js-submenu');
                if (menuItem.classList.contains('active')) {
                    menuItem.classList.remove('active');
                } else {
                    closeAllSubmenusExcept(submenu);
                    menuItem.classList.add('active');
                }
            });
        }
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * mobile menu - subnav
    // *
    // *

    const nav = document.querySelectorAll(".js-subnav");
    
    if (nav !== null) {
        for (var i=0; i < nav.length; i++) {
            nav[i].onclick = function(e) {
                this.parentNode.parentNode.classList.toggle("active");
            }
        }
    }

    
 // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * pricelist
    // *
    // *
    $(".js-pricelist-item").click(function () {
        $(this).toggleClass("active");
        $(this).siblings().removeClass("active")
        $(this).find(".js-pricelist-dropdown").slideToggle();
        $(this).siblings().find(".js-pricelist-dropdown").slideUp();
    })


});


function liveSearch() {
    // Locate the item elements
    let groups = document.querySelectorAll('.js-pricelist-group');
    
    // Locate the search input
    let search_query = document.querySelector(".js-pricelist-input").value;

    // Loop through the items
    for (var j = 0; j < groups.length; j++) {
        let group_hidden = true;
        let items = groups[j].querySelectorAll('.js-pricelist-item');
        for (var i = 0; i < items.length; i++) {
            console.log(items[i])
            // If the text is within the item...
            if (items[i].getAttribute("data-value").toLowerCase()
                // ...and the text matches the search query...
                .includes(search_query.toLowerCase())) {
                // ...remove the `.hidden` class.
                items[i].classList.remove("hidden");
                group_hidden = false;
            } else {
                // Otherwise, add the class.
                items[i].classList.add("hidden");
            }
        }
        if (group_hidden) {
            groups[j].classList.add("hidden")
        } else {
            groups[j].classList.remove("hidden")
        }
    }
}



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *

$(window).on("load scroll", function () {
    var scrollBottom = $(document).height() - $(window).height() - $(window).scrollTop();
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
    // hide cta buttons in footer
    if (scrollBottom <= 410) {
        $(".js-cta").addClass("hide");
    } else {
        $(".js-cta").removeClass("hide");
    }
});

$(window).on("load scroll", function () {
    if ($(".js-side-cta") && window.innerWidth > 1000) {
        if ($(window).scrollTop() < 1) {
            $(".js-side-cta").addClass("active");
        } else {
            $(".js-side-cta").removeClass("active");
        }
    }
});






